<template>
  <div>
    <a-form-model ref="form" :model="form">
      <div>
        <a-space>
          <div class="search_brand">
            <span
              :class="['all_item', !form.principalId ? 'active_item' : '', allBrandList.length ? 'brand_item' : '']"
              @click="handleBrandClick(undefined)"
              >全部品牌</span
            >
            <span
              :class="['brand_item', form.principalId === item.principalId ? 'active_item' : '']"
              v-for="item in allBrandList"
              :key="item.principalId"
              @click="handleBrandClick(item.principalId)"
              >{{ item.principalName }}</span
            >
          </div>
          <a-select
            style="width: 200px"
            v-model="form.operatorGroupId"
            placeholder="请选择团队"
            show-search
            option-filter-prop="children"
            allowClear
            @change="handleSearch"
          >
            <a-select-option v-for="item in allTeamList" :key="item.id" :value="item.id">{{
              item.groupName
            }}</a-select-option>
          </a-select>

          <a-select
            v-model="form.groupId"
            style="width: 200px"
            placeholder="账号分组"
            show-search
            option-filter-prop="children"
            @change="handleSearch"
            allowClear
          >
            <a-select-option v-for="item in allGroupList" :key="item.id" :value="item.id">{{
              item.groupName
            }}</a-select-option>
          </a-select>
        </a-space>
      </div>
      <div style="margin: 10px 0px; display: flex">
        <SearchItemList
          style="margin-right: 20px"
          v-model="form.status"
          :itemList="runningStatusList"
          :allowNullCheck="true"
          @click="handleStatusClick('running')"
        />
        <SearchItemList
          v-model="form.stopStatus"
          :itemList="stopStatusList"
          :allowNullCheck="true"
          @click="handleStatusClick('stop')"
        />
      </div>
      <div style="display: flex; justify-content: space-between">
        <div>
          <a-input-search
            style="margin-right: 15px; width: 380px"
            placeholder="搜索小红书昵称、小红书号、成员名称、成员编码"
            v-model.trim="form.search"
            @search="handleSearch"
            allowClear
          ></a-input-search>
          <a-select
            v-model="form.operatorId"
            style="width: 200px"
            placeholder="运营人"
            show-search
            option-filter-prop="children"
            @change="handleSearch"
            allowClear
          >
            <a-select-option v-for="item in allMemberList" :key="item.id" :value="item.id">{{
              item.operatorName
            }}</a-select-option>
          </a-select>
        </div>
        <div>
          <a-button @click="handleExport" :loading="exportLoading">导出</a-button>
          <a-button v-show="!selectedRowKeys.length" disabled style="margin: 0 15px">批量操作</a-button>
          <a-dropdown>
            <a-button v-show="selectedRowKeys.length" style="margin: 0 15px">批量操作</a-button>
            <a-menu slot="overlay">
              <a-menu-item>
                <a-button type="link" @click="handleJoinGroup(selectedRowKeys, 'batch')">加入分组</a-button>
              </a-menu-item>
              <a-menu-item>
                <a-popconfirm title="移除后不影响账号数据" ok-text="确定" cancel-text="取消" @confirm="confirmStop">
                  <a-button type="link" :loading="cancelLoading">停止运营</a-button>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <a-button type="primary" @click="handleAddAccount">添加小红书号</a-button>
        </div>
      </div>
    </a-form-model>

    <div class="table-container">
      <a-table
        style="margin-top: 10px"
        :loading="tableLoading"
        :columns="columns"
        :scroll="{ x: '100%' }"
        :data-source="dataList"
        :rowKey="(record) => record.id"
        :row-selection="rowSelection"
        :pagination="false"
      >
        <div class="media-column" slot="mediaAccount" slot-scope="text, record">
          <div class="media-left" v-show="record.avatar">
            <img :src="record.avatar" alt="头像" referrerpolicy="no-referrer" />
          </div>
          <div class="media-right">
            <div style="margin-left: 15px" class="blue-text" @click="handleJumpXHS(record)">{{ record.nickname }}</div>
            <div class="line-flex right-txt">
              <div class="logo" v-show="record.code">
                <img src="@/assets/icon/xhs_logo.png" alt="" />
              </div>
              <div>{{ record.code || '-' }}</div>
            </div>
          </div>
        </div>
        <div slot="operatorTitle">
          <div>
            运营人
            <a-popover>
              <template slot="content">
                <div>根据所属成员的运营负责人自动设置</div>
              </template>
              <span style="cursor: pointer">
                <a-icon type="question-circle" />
              </span>
            </a-popover>
          </div>
        </div>
        <div class="media-column" slot="characters" slot-scope="text, record">
          <div class="avatar-right">
            <div>{{ personalityTypeObject[record.personalityType] }}</div>
          </div>
        </div>
        <div style="text-align: left" slot="belongMember" slot-scope="text, record">
          <div>
            名称：
            <!-- class="blue-text" @click="handleDetailJump(record)" -->
            <span v-if="record.matrixMemberName">{{ record.matrixMemberName }}</span>
            <span v-else> - </span>
          </div>
          <div>
            编号：<span>{{ record.memberCode || '-' }}</span>
          </div>
        </div>
        <div slot="p1OauthStatus" slot-scope="text, record">
          <a-tooltip>
            <template slot="title">
              <div>授权时间：{{ record.p1OauthTime || '-' }}</div>
            </template>
            <div class="sync-succ" v-show="record.p1OauthStatus === 1">
              笔记数据<a-icon style="margin-left: 5px" type="check-circle" />
            </div>
          </a-tooltip>
          <a-tooltip>
            <template slot="title">
              <div>失效时间：{{ record.p1OauthExpireTime || '-' }}</div>
            </template>
            <div class="sync-err" v-show="record.p1OauthStatus !== 1">
              笔记数据<a-icon style="margin-left: 5px" type="close-circle" />
            </div>
          </a-tooltip>
        </div>
        <div slot="dressName" slot-scope="text, record">
          <div v-if="record.postLoc">
            <div>{{ record.postLoc.name || '-' }}</div>
            <div style="font-size: 12px; color: #cccccc">{{ record.postLoc.subname || '-' }}</div>
          </div>
          <div v-else>-</div>
        </div>
        <div slot="operation" slot-scope="text, record">
          <template v-if="record.status === 'STOP'">
            <div style="cursor: not-allowed">停止运营</div>
          </template>
          <template v-else-if="record.accountStatus == 1">
            <div style="cursor: not-allowed">账号已注销</div>
          </template>
          <template v-else-if="!record.memberId">
            <div style="cursor: not-allowed">账号已从成员解绑</div>
          </template>
          <template v-else>
            <a-button type="link" size="small" @click="handleOpenEditCharacterModal(record)">设置</a-button>
            <a-dropdown>
              <a class="ant-dropdown-link" @click="(e) => e.preventDefault()"> 更多 <a-icon type="down" /> </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a-button
                    type="link"
                    size="small"
                    @click="handleOpenXHS(record, 'notelist')"
                    :loading="record.loading"
                    >笔记列表</a-button
                  >
                </a-menu-item>
                <a-menu-item>
                  <a-button type="link" size="small" @click="handleJoinGroup(record.code, 'single')">加入分组</a-button>
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm
                    title="移除后不影响账号数据"
                    ok-text="确定"
                    cancel-text="取消"
                    placement="left"
                    @confirm="confirmStopSingle(record.id)"
                  >
                    <a-button type="link" size="small" style="color: #d9001b">停止运营</a-button>
                  </a-popconfirm>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </template>
        </div>
      </a-table>
      <base-pagination
        :currentPage="pagination.current"
        :options="['15', '30', '50']"
        :pageSize="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </div>

    <!-- 加入分组 -->
    <a-modal
      class="group_modal"
      width="500px"
      title="媒体号加入分组"
      :visible="showJoinModal"
      @close="handleJoinClose"
      @cancel="handleJoinClose"
    >
      <div>
        <span>已选：</span>
        <span>{{ selectMedia.length }}</span>
      </div>
      <div style="display: flex">
        <div>我创建的分组：</div>
        <div>
          <template v-if="myGroupList.length">
            <a-checkbox-group v-model="selectMedia">
              <div style="display: flex; flex-direction: column">
                <a-checkbox v-for="(item, index) in myGroupList" :key="index" :value="item.id">{{
                  item.groupName
                }}</a-checkbox>
              </div>
            </a-checkbox-group>
          </template>
          <template v-else>
            <span> 请先创建分组 </span>
          </template>
        </div>
      </div>
      <div slot="footer" style="display: flex; justify-content: space-between">
        <a-button type="link" @click="showAddGroup = true">新建分组</a-button>
        <span
          ><a-space>
            <a-button @click="handleJoinClose">取消</a-button>
            <a-button type="primary" :disabled="!selectMedia.length" :loading="joinLoading" @click="handleJoinOk"
              >确定</a-button
            >
          </a-space></span
        >
      </div>
    </a-modal>

    <!-- 新建分组 -->
    <a-modal
      width="500px"
      :zIndex="1001"
      title="新建分组"
      :visible="showAddGroup"
      @close="handleAddClose"
      @cancel="handleAddClose"
    >
      <div>
        <span style="margin-right: 20px"><span style="color: #da0722">*</span>分组名称：</span>
        <a-input style="width: 60%" :maxLength="30" v-model.trim="addGroupName"></a-input>
      </div>
      <template slot="footer">
        <a-button key="back" @click="handleAddClose"> 取消 </a-button>
        <a-button
          key="submit"
          type="primary"
          :disabled="!(addGroupName && addGroupName.trim())"
          :loading="addLoading"
          @click="handleAddOk"
        >
          确定
        </a-button>
      </template>
    </a-modal>

    <!-- 添加小红书号 -->
    <AccountUploadComp ref="AccountUploadComp" @refresh="handleSearch" />

    <preview-modal :visible="preview_visible" :preview-src="preview_src" width="50%" @cancel="handlePreviewCancel" />
  </div>
</template>

<script>
import api from '@/api/xhsAgencyApi';
import BaseUpload from '@/components/BaseUpload/BaseUpload.vue';
import { personalityTypeList, personalityTypeObject, stopStatusList, runningStatusList, columns } from './data.js';
import PreviewModal from '@/components/PreviewModalVideo/PreviewModalVideo';
import { trackRequest } from '@/utils/track';
import SearchItemList from '@/components/xhsAgencyTool/SearchItemList';
import AccountUploadComp from './components/AccountUploadComp.vue';
import { downloadExcel } from '@/utils';

export default {
  components: {
    BaseUpload,
    PreviewModal,
    SearchItemList,
    AccountUploadComp,
  },
  data() {
    return {
      stopStatusList,
      runningStatusList,
      currentPosition: {},
      positionList: [],
      positionLoading: false,
      personalityTypeList,
      personalityTypeObject,
      allBrandList: [],
      form: {
        groupId: undefined,
        creator: undefined,
        principalId: undefined,
        operatorGroupId: undefined,
        status: 'NORMAL',
        stopStatus: undefined,
        search: undefined,
        operatorId: undefined,
      },
      columns,
      selectedRowKeys: [],
      tableLoading: false,
      btnLoading: false,
      cancelLoading: false,
      dataList: [],
      pagination: {
        current: 1,
        pageSize: 15,
        total: 1,
      },
      // 图片预览
      preview_visible: false,
      preview_src: '',
      // 加入分组
      showJoinModal: false,
      joinLoading: false,
      selectMedia: [],
      myGroupList: [],
      allGroupList: [],
      showAddGroup: false,
      addGroupName: undefined,
      addLoading: false,

      allTeamList: [],
      allMemberList: [],

      exportLoading: false,
    };
  },
  created() {
    if (this.$route.query.memberCode) {
      const memberCode = this.$route.query.memberCode;
      this.$router.replace({ query: {} }, () => {
        this.form.search = memberCode;
        this.init();
      });
    } else {
      this.init();
    }
  },
  methods: {
    init() {
      this.getDataList();
      this.getAllMediaList();
      this.getMyGroupList();
      this.getAllBrandList();
      this.getOperatorTeamList();
      this.getAllMemberList();
    },
    async getDataList() {
      const params = {
        ...this.form,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      params.status = this.form.stopStatus ? this.form.stopStatus : this.form.status;
      if (params.stopStatus) delete params.stopStatus;
      this.tableLoading = true;
      await this.handleRequest(
        'getAgencyList',
        (data) => {
          this.dataList = data.list.map((val) => {
            val.loading = false;
            return val;
          });
          this.pagination.total = data.total;
          this.selectedRowKeys = [];
          this.getStatusList(params);
        },
        params
      ).finally(() => (this.tableLoading = false));
    },
    handleSearch() {
      this.pagination.current = 1;
      this.getDataList();
    },
    handleReset() {
      this.form = this.$options.data().form;
      this.handleSearch();
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getDataList();
    },
    // 停止运营
    confirmStop() {
      if (!this.selectedRowKeys.length) return this.$message.info('请至少选择一个媒体号');
      this.cancelLoading = true;
      this.handleRequest(
        'handleAccountSwitch',
        () => {
          this.$message.success('操作成功');
          this.selectedRowKeys = [];
          this.getDataList();
        },
        { status: 'STOP', ids: this.selectedRowKeys }
      ).finally(() => (this.cancelLoading = false));
    },
    confirmStopSingle(id) {
      // handleAccountSwitch handleDeleteMedia
      this.handleRequest(
        'handleAccountSwitch',
        () => {
          this.$message.success('操作成功');
          this.getDataList();
        },
        { status: 'STOP', ids: [id] }
        // {  ids: [id] }
      );
    },
    // 打开编辑媒体号
    handleOpenEditCharacterModal(item) {
      this.handleTrack('edit_media');
      this.$refs.AccountUploadComp.handleSetManager(item);
    },
    // 小红书主页跳转
    async handleJumpXHS(value) {
      const hide = this.$message.loading('查询中...', 0);
      await this.handleRequest(
        'getXhsAccountPage',
        (data) => {
          window.open(data, '_blank');
        },
        {
          authorId: value.authorId,
        }
      ).finally(() => hide());
    },
    // 经销商跳转
    async handleDetailJump(record) {
      const hide = this.$message.loading('查询中...', 0);
      await this.handleRequest(
        'getDealerDetail',
        (data) => {
          const routeUrl = this.$router.resolve({
            name: 'dealerDetail',
            query: {
              dealer_id: data.dealerId,
              dealer_name: record.matrixMemberName,
            },
          });
          window.open(routeUrl.href, '_blank');
        },
        {
          matrixMemberId: record.memberId,
          principalId: record.principalId,
        }
      ).finally(() => hide());
    },
    // 账号统计跳转
    handleJumpToJJT(authorId) {
      this.handleTrack('jump_to_jjt_media_detail');

      window.open(
        `${process.env.VUE_APP_JJT_URL}/jjt-new/matrix/account-detail?platform=5&authorId=${authorId}`,
        '_blank'
      );
    },
    // 笔记列表跳转
    async handleOpenXHS(val, type) {
      this.handleTrack('jump_to_xhs_note_list');

      if (!val.p1OauthStatus || val.p1OauthStatus !== 1) {
        this.$warning({
          title: '未对齐笔记数据',
          content: '统计数据不准确，无法登录小红书账号，需要在风火轮APP中对齐数据。',
        });
      } else {
        this.getXHSCookie(val, type);
      }
    },
    // 获取小红书cookie并处理跳转
    async getXHSCookie(val, pageType) {
      this.dataList.forEach((value) => {
        if (value.id === val.id) {
          value.loading = true;
        }
      });
      await this.handleRequest(
        'getXHSCookies',
        (data) => {
          if (window.ipcRenderer) {
            const URL = {
              notelist: 'https://creator.xiaohongshu.com/creator/notemanage',
              publish: 'https://creator.xiaohongshu.com/publish/publish',
            };
            const cookieParams = JSON.parse(data.format_cookies);
            cookieParams.url = URL[pageType] || '';
            window.ipcRenderer.send('open-xhs-page', cookieParams);
          } else {
            this.$warning({
              title: '需要在代运营工具中进行此操作',
            });
          }
        },
        { authorId: val.authorId }
      ).finally(() => {
        this.dataList.forEach((value) => {
          if (value.id === val.id) {
            value.loading = false;
          }
        });
      });
    },
    /**
     * 查看图片
     * @param {*} url 图片地址
     */
    openBigImg(url) {
      if (!url) {
        return this.$message.error('URL为空，不支持查看！');
      }
      this.width = '480px';
      this.preview_visible = true;
      this.preview_src = url;
    },
    handlePreviewCancel() {
      this.preview_src = '';
      this.preview_visible = false;
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleTrack(trackEvent, trackParams = {}) {
      const to = this.$route;
      trackRequest(to, trackEvent, trackParams);
    },
    handleJoinGroup(record, type) {
      this.authorCodes = [];
      if (type === 'single') {
        record && this.authorCodes.push(record);
      } else {
        record.forEach((val) => {
          const code = this.dataList.find((s) => s.id === val)?.code || undefined;
          code && this.authorCodes.push(code);
        });
      }
      console.log(this.authorCodes);
      if (this.authorCodes.length) {
        this.showJoinModal = true;
      } else {
        this.$message.info('媒体号有误');
      }
    },
    handleJoinOk() {
      const params = {
        authorCodes: this.authorCodes,
        groupIds: this.selectMedia,
        masterAccountName: this.$router.app.user.name,
        masterAccountUserId: this.$router.app.user.id,
        masterAccountUsername: this.$router.app.user.username,
      };
      this.joinLoading = true;
      this.handleRequest(
        'patchMediaInGroup',
        (data) => {
          if (data.failList.length) {
            this.$message.error(`部分添加失败: ${data.failList}`);
          } else {
            this.$message.success('添加成功');
          }
          this.handleJoinClose();
        },
        params
      ).finally(() => {
        this.joinLoading = false;
      });
    },
    handleJoinClose() {
      this.selectMedia = [];
      this.showJoinModal = false;
    },
    handleAddClose() {
      this.showAddGroup = false;
      this.addGroupName = undefined;
    },
    getMyGroupList() {
      this.handleRequest(
        'getMyMediaGroupList',
        (data) => {
          this.myGroupList = data;
        },
        { groupScene: 6 }
      );
    },
    getAllMediaList() {
      const params = {
        pageNum: 1,
        pageSize: 9999,
        groupScene: 6,
      };
      this.handleRequest(
        'getMediaGroupList',
        (data) => {
          this.allGroupList = data.list;
        },
        params
      );
    },
    handleAddOk() {
      if (!this.addGroupName) return this.$message.info('请填写分组名称');
      this.addLoading = true;
      this.handleRequest(
        'addMediaGroup',
        () => {
          this.selectMedia = [];
          this.myGroupList = [];
          this.getMyGroupList();
          this.handleAddClose();
        },
        { groupName: this.addGroupName, groupScene: 6, groupType: 2, isPublish: 2, status: 2 }
      ).finally(() => (this.addLoading = false));
    },
    handleAddAccount() {
      this.$refs.AccountUploadComp.openUpload();
    },
    handleBrandClick(value) {
      this.form.principalId = value;
      this.form.operatorGroupId = undefined;
      this.allTeamList = [];
      this.getOperatorTeamList(value);
      this.handleSearch();
    },
    async getAllBrandList() {
      const { code, data, message } = await api.getBrandManageList({ size: 999, page: 1 });
      if (code === 200) {
        this.allBrandList = data.list.filter((v) => v.status === 'NORMAL');
      } else {
        this.$message.error(message);
      }
    },
    async getOperatorTeamList(principalId = undefined) {
      const { code, message, data } = await api.getTeamManageList({ page: 1, size: 999, principalId });
      if (code === 200) {
        this.allTeamList = data.list;
      } else {
        this.$message.error(message);
      }
    },
    handleStatusClick(status) {
      if (status !== 'stop') {
        this.form.stopStatus = undefined;
      } else {
        this.form.status = undefined;
      }
      this.handleSearch();
    },
    async getAllMemberList() {
      const { code, data, message } = await api.getTeamMember({ page: 1, size: 999, isDeleted: 0 });
      if (code === 200) {
        this.allMemberList = data.list;
      } else {
        this.$message.error(message);
      }
    },
    async getStatusList(params) {
      const { code, data, message } = await api.getAccountStatistics(params);
      if (code === 200) {
        this.runningStatusList.forEach((l) => {
          data.forEach((d) => {
            if (l.value == d.status) {
              l.number = d.count;
            }
          });
        });
        this.stopStatusList[0].number = data.find((v) => v.status == 'STOP')?.count;
      } else {
        this.$message.error(message);
      }
    },
    async handleExport() {
      const params = {
        ...this.form,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      params.status = this.form.stopStatus ? this.form.stopStatus : this.form.status;
      if (params.stopStatus) delete params.stopStatus;
      this.exportLoading = true;
      const fileName = `媒体号列表`;
      const res = await api.exportMediaList(params).finally(() => (this.exportLoading = false));
      downloadExcel(res, fileName);
    },
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        getCheckboxProps: (record) => ({
          props: {
            status: record.status,
            memberId: record.memberId,
            accountStatus: record.accountStatus,
            disabled: record.status === 'STOP' || record.accountStatus == 1 || !record.memberId,
          },
        }),
      };
    },
  },
};
</script>

<style lang="less" scoped>
.form {
  .ant-form-item {
    display: flex;
  }
}
.search-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #fff;

  .search-bar-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    ::v-deep .ant-form-item {
      width: 25%;
      flex-shrink: 0;
    }
  }

  /deep/ .ant-form-item {
    margin-bottom: 0;
  }
}

.table-container {
  padding: 20px;
  background-color: #fff;

  .table-line {
    margin: 10px 0;
    padding: 5px 20px;
    border-radius: 5px;
    background-color: #e6f7ff;
    border: 1px solid #8fc9ff;
  }
}

.line-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.media-column {
  display: flex;
  margin-top: 10px;
  text-align: left;

  .media-left {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .avatar-left {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .media-right {
    flex: 1;

    .right-txt {
      justify-content: start;

      .logo {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .avatar-right {
    flex: 1;
    display: flex;
    align-items: center;
  }
}

.blue-text {
  color: #1890ff;
  cursor: pointer;
}

.sync-err {
  margin: 0 auto;
  width: 100px;
  border-radius: 5px;
  color: #f59a23;
  background-color: #facd91;
}

.sync-succ {
  margin: 0 auto;
  width: 100px;
  border-radius: 5px;
  color: #5ac822;
  background-color: #caf982;
}

.fail-list {
  .fail-list-item {
    margin: 0 20px 5px;
    border-radius: 5px;
  }
}

.sub-title {
  margin-left: 8px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}

.group_modal {
  /deep/ .ant-checkbox-wrapper {
    margin: 0 0 5px 0;
  }
}

.search_brand {
  .all_item {
    color: #adadad;
    padding: 5px 10px;
    border: 1px solid #bfbfbf;
    cursor: pointer;
    border-radius: 6px;
  }

  .brand_item {
    color: #adadad;
    padding: 5px 10px;
    border: 1px solid #bfbfbf;
    cursor: pointer;
    background-color: #fff;
    line-height: 30px;

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }

    &.active_item {
      border: 1px solid #1890ff;
      background-color: #1890ff;
      color: #fff;
    }
  }
}
</style>
